import React, { useState, useEffect, useRef } from "react";
import
{
  Form,
  Input,
  Button,
  Space,
} from "antd";
import { postRequest } from "../../axios";
import { getSessionData, getSchoolData, getUserData } from "../../utils/Helpers";

import { ErrorNotificationMsg, SuccessNotificationMsg, } from "../../utils/NotificationHelper";
import { useOutletContext } from "react-router-dom";

const StudentListFormative = ( props ) =>
{
  const formRef = useRef();
  const [ tabulation, setTabulation ] = useState( null );
  const [ marksList, setMarksList ] = useState( [] );
  const [ btnLoading, setBtnLoading ] = useState( false );
  const setSpinner = useOutletContext();

  useEffect( () =>
  {
    getTabulation();
  }, [] );

  const getTabulation = async () =>
  {
    setSpinner( true );
    const response = await postRequest( "exam-marks-tabulation-formative-list", {
      // schoolCode: getSchoolData().school_code,
      sessionCode: getSessionData().rcode,
      classSection: props.classSection,
      examId: props.examId,
      subjectId: props.subjectId,
      // userId: getUserData().tid,
    } );

    setSpinner( false );
    if ( response.data.error > 0 )
    {
      ErrorNotificationMsg( response.data.errmsg );
    } else
    {
      console.log( response.data.response.data );
      setTabulation( response.data.response.data );
      setMarksList( response.data.response.data.students );
    }
  };

  const checkMarks = ( event, student, indicator ) =>
  {
    let fm = {
      a: tabulation.fullMarks[ "fmA" ],
      b: tabulation.fullMarks[ "fmB" ],
      c: tabulation.fullMarks[ "fmC" ]
    };
    let mo = parseInt( event.target.value );
    if ( mo !== 111 && mo !== 222 && mo !== 333 )
    {
      if ( fm[ indicator ] < mo )
      {
        event.target.value = 0;
        ErrorNotificationMsg( "Marks can not be more than " + fm[ indicator ] );
      }

      handleMarksChange( event, student, indicator );

    }
  };

  const handleMarksChange = async ( event, student, indicator ) =>
  {
    let mo = event.target.value;
    let items = [ ...marksList ];
    let documentIndex = items.findIndex(
      ( res ) => res.classId === student.classId
    );
    let item = { ...items[ documentIndex ] };


    items[ documentIndex ] = item;
    if ( indicator === "a" )
    {
      item.marks.moA = mo;
      student.marks.moA = mo;
    }
    else if ( indicator === "b" )
    {
      item.marks.moB = mo;
      student.marks.moB = mo;
    }
    else if ( indicator === "c" )
    {
      item.marks.moC = mo;
      student.marks.moC = mo;
    }

    setMarksList( items );

    const payload = {
      sessionCode: getSessionData().rcode,
      classSection: props.classSection,
      examId: props.examId,
      subjectId: props.subjectId,
      form_data: {
        marks: [
          {
            "classId": student.classId,
            "indicator": indicator,
            "marks": mo
          }
        ]
      }
    };

    try
    {
      const res = await postRequest( "exam-marks-tabulation-formative-update", payload );

      if ( res.data.error === 0 )
      {
        // SuccessNotificationMsg("Success", "Marks successfully updated.");
        // props.handleShowList(false);
      } else
      {
        ErrorNotificationMsg( "Sorry!! Unable to save marks." );
      }
    } catch ( error )
    {
      ErrorNotificationMsg( "Something went wrong!!" );
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div id="panel-1" className="panel">
            <div className="panel-hdr">
              <h2>Student List of Class : { props.classSection }</h2>
            </div>
            <div className="panel-container show">
              <div className="panel-content">
                <Form
                  autoComplete="off"
                  layout="vertical"
                  ref={ formRef }
                >
                  <div className="table-responsive">
                    <table className="table table-sm table-bordered table-hover">
                      <thead className="thead-themed">
                        <tr className="text-center">
                          <th>ROLL</th>
                          <th>NAME</th>
                          { tabulation?.fullMarks &&
                            Object.entries( tabulation.fullMarks ).map( ( [ key, value ] ) => (
                              <th key={ key }>
                                { key.toUpperCase() } [F.M.- { value }]
                              </th>
                            ) ) }
                        </tr>
                      </thead>
                      <tbody>
                        { marksList &&
                          marksList.map( ( student ) =>
                          {
                            return (
                              <tr key={ student?.classId }>
                                <td>{ student?.stdRoll }</td>
                                <td><strong>{ student?.stdName }</strong></td>

                                { student?.marks &&
                                  Object.entries( student.marks ).map( ( [ key, value ] ) =>
                                  (
                                    <td>
                                      { tabulation?.lockTabulation === 1 ?
                                        <Input
                                          type="text"
                                          name="marks"
                                          //value={student?.marks}
                                          value={
                                            value === '111.0' || value === '222.0' || value === '333.0'
                                              ? Math.floor( value )
                                              : value
                                          }
                                          disabled={ true }
                                        />
                                        :
                                        <Input
                                          type="text"
                                          name="marks"
                                          //value={student?.marks}
                                          value={
                                            value === '111.0' || value === '222.0' || value === '333.0'
                                              ? Math.floor( value )
                                              : value
                                          }
                                          id={ `inputId${ student?.classId }_${ key }` }

                                          onBlur={ ( event ) => checkMarks( event, student, key == "moA" ? "a" : key == "moB" ? "b" : key == "moC" ? "c" : key ) }
                                          onChange={ ( event ) => handleMarksChange( event, student, key == "moA" ? "a" : key == "moB" ? "b" : key == "moC" ? "c" : key ) }
                                        />
                                      }
                                    </td>
                                  )
                                  ) }

                              </tr>
                            );
                          } ) }
                      </tbody>
                    </table>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentListFormative;