import React, { useState, useEffect } from "react";
import { postRequest } from "../../axios";
import { getSessionData } from "../../utils/Helpers";

import PageHeader from "../common/PageHeader";
import userIcon from "../../images/userIcon.jpg";
import { Space } from "antd";
import { Link, useOutletContext } from "react-router-dom";

const StudentProfile = () => {
  const [studentProfile, setStudentProfile] = useState([]);
  const [showEditButton, setShowEditButton] = useState(false);
  const setSpinner = useOutletContext();

  useEffect(() => {
    getStudentProfile();
  }, []);

  const getStudentProfile = async () => {
    setSpinner(true);
    const response = await postRequest("get-student-personal-details");
    setStudentProfile(response.data.response);
    setSpinner(false);
  };

  return (
    <div id="content">
      <PageHeader
        pageTitle="My Profile"
        pageIcon={<i className="subheader-icon fal fa-user"></i>}
      />
      <div className="row">
        <div className="col-md-12">
          <div id="panel-1" className="panel">
            <div className="panel-hdr">
              <h2>My Profile</h2>
              {studentProfile.profile_update_status === 1 ? (
                <div className="panel-toolbar">
                  <Space>
                    <Link
                      to="/student-profile-edit"
                      className="btn btn-sm btn-success waves-effect waves-themed"
                    >
                      <i className="fal fa-edit"></i> Edit Profile
                    </Link>
                  </Space>
                </div>
              ) : ""}
              {studentProfile.profile_update_status === 2 ? (
                <div className="panel-toolbar">
                  <span className="badge badge-primary">IN REVIEW</span>
                </div>
              ) : ""}
            </div>
            <div className="panel-container show">
              <div className="panel-content">
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-2">
                    <div className="card border m-auto m-lg-0">
                      <img
                        src={studentProfile?.image_url}
                        className="img-thumbnail"
                        alt="Profile Photo"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = userIcon;
                        }}
                      />
                    </div>
                    <table className="table table-sm table-bordered table-hover table-striped w-100 mt-2">
                      <thead className="thead-themed">
                        <tr><th colSpan={2}>PRESENT CLASS</th></tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Session</td><td>{studentProfile.student_class?.std_session}</td>
                        </tr>
                        <tr>
                          <td>Class</td><td>{studentProfile.student_class?.std_class}</td>
                        </tr>
                        <tr>
                          <td>Section</td><td>{studentProfile.student_class?.std_section}</td>
                        </tr>
                        <tr>
                          <td>Roll</td><td>{studentProfile.student_class?.std_roll}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-5">
                    <table className="table table-sm table-bordered table-hover table-striped w-100">
                      <thead className="thead-themed">
                        <tr><th colSpan={2} className="text-center">PERSONAL DETAILS</th></tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Student's ID</td><td><strong>{studentProfile?.std_id}</strong></td>
                        </tr>
                        {studentProfile?.std_bs_code &&
                          <tr>
                            <td>Banglar Siksha ID</td><td><strong>{studentProfile?.std_bs_code}</strong></td>
                          </tr>
                        }
                        <tr>
                          <td>Student's Name </td><td><strong>{studentProfile?.std_name}</strong></td>
                        </tr>
                        <tr>
                          <td>Date of Birth</td><td>{studentProfile?.std_birth_date}</td>
                        </tr>
                        <tr>
                          <td>Gender </td><td>{studentProfile?.std_gender_text}</td>
                        </tr>
                        <tr>
                          <td>Caste</td><td>{studentProfile?.std_caste_text}</td>
                        </tr>
                        <tr>
                          <td>Blood Group</td><td>{studentProfile?.std_blood_group_text}</td>
                        </tr>
                        <tr>
                          <td>Second Language</td><td>{studentProfile?.second_lang}</td>
                        </tr>
                        <tr>
                          <td>Third Language</td><td>{studentProfile?.third_lang}</td>
                        </tr>
                        <tr>
                          <td>Religion</td><td>{studentProfile?.std_religion_text}</td>
                        </tr>
                        <tr>
                          <td>Aadhaar Card No.</td><td>{studentProfile?.std_aadhaar}</td>
                        </tr>
                      </tbody>
                    </table>

                    <table className="table table-sm table-bordered table-hover table-striped w-100">
                      <thead className="thead-themed">
                        <tr><th colSpan={2} className="text-center">ADMISSION DETAILS</th></tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Admission Number</td><td><strong>{studentProfile?.std_adm_no}</strong></td>
                        </tr>
                        <tr>
                          <td>Date of Admission</td><td><strong>{studentProfile?.std_adm_date}</strong></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-5">
                    <table className="table table-sm table-bordered table-hover table-striped w-100">
                      <thead className="thead-themed">
                        <tr><th colSpan={2} className="text-center">PARENT DETAILS</th></tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Father's Name</td><td>{studentProfile?.std_father_name}</td>
                        </tr>
                        <tr>
                          <td>Father's Contact No.</td><td>{studentProfile?.std_father_contact}</td>
                        </tr>
                        <tr>
                          <td>Mother's Name</td><td>{studentProfile?.std_mother_name}</td>
                        </tr>
                        <tr>
                          <td>Mother's Contact No.</td><td>{studentProfile?.std_mother_contact}</td>
                        </tr>
                      </tbody>
                    </table>

                    <table className="table table-sm table-bordered table-hover table-striped w-100">
                      <thead className="thead-themed">
                        <tr><th colSpan={2} className="text-center">CONTACT DETAILS</th></tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Address (House No./Village/Para/Road)</td><td>{studentProfile?.std_village}</td>
                        </tr>
                        <tr>
                          <td>Habitation/Locality</td><td>{studentProfile?.std_habitation_locality}</td>
                        </tr>
                        <tr>
                          <td>Post Office</td><td>{studentProfile?.std_po}</td>
                        </tr>
                        <tr>
                          <td>Police Station</td><td>{studentProfile?.std_ps}</td>
                        </tr>
                        <tr>
                          <td>District</td><td>{studentProfile?.std_district_text}</td>
                        </tr>
                        <tr>
                          <td>Block/Municipality</td><td>{studentProfile?.std_block_text}</td>
                        </tr>
                        <tr>
                          <td>PIN</td><td>{studentProfile?.std_pin}</td>
                        </tr>
                        <tr>
                          <td>Primary Mobile No.</td><td>{studentProfile?.std_contact}</td>
                        </tr>
                        <tr>
                          <td>Whatsapp</td><td>{studentProfile?.std_contact_whatsapp}</td>
                        </tr>
                      </tbody>
                    </table>

                    {studentProfile?.bank_details && studentProfile?.bank_details?.account_no &&
                      <table className="table table-sm table-bordered table-hover table-striped w-100">
                        <thead className="thead-themed">
                          <tr><th colSpan={2} className="text-center">BANK DETAILS</th></tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Bank Name</td><td>{studentProfile?.bank_details?.bank_name}</td>
                          </tr>
                          <tr>
                            <td>Bank Branch</td><td>{studentProfile?.bank_details.branch_name}</td>
                          </tr>
                          <tr>
                            <td>Account No.</td><td>{studentProfile?.bank_details.account_no}</td>
                          </tr>
                          <tr>
                            <td>IFS Code</td><td>{studentProfile?.bank_details.ifsc_code}</td>
                          </tr>
                        </tbody>
                      </table>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentProfile;