import React, { useState, useEffect, useRef } from "react";
import { Form, Input, Button, Space } from "antd";
import { postRequest } from "../../axios";
import
{
  getSessionData,
  getSchoolData,
  getUserData,
} from "../../utils/Helpers";

import
{
  ErrorNotificationMsg,
  SuccessNotificationMsg,
} from "../../utils/NotificationHelper";
import { useOutletContext } from "react-router-dom";

const StudentListDPLS = ( props ) =>
{
  const formRef = useRef();
  const [ tabulation, setTabulation ] = useState( null );
  const [ marksList, setMarksList ] = useState( [] );
  const [ btnLoading, setBtnLoading ] = useState( false );
  const setSpinner = useOutletContext();


  const marksKeys = [ "ls", "cs", "es", "cos", "csk", "fs", "cspss", "scs", "dms", "is", "os", "ecs", "rs", "aspa", "l" ];



  useEffect( () =>
  {
    getTabulation();
  }, [] );

  const getTabulation = async () =>
  {
    setSpinner( true );
    const response = await postRequest(
      "exam-marks-tabulation-dpls-all-student",
      {
        sessionCode: getSessionData().rcode,
        classSection: props.classSection,
      }
    );

    setSpinner( false );
    if ( response.data.error > 0 )
    {
      ErrorNotificationMsg( response.data.errmsg );
    } else
    {
      console.log( response.data.response.data );
      setTabulation( response.data.response.data );
      setMarksList( response.data.response.data.students );
    }
  };

  const checkMarks = ( event, student, indicator ) =>
  {
    console.log( "Checking marks" );
    console.log( event );

    let inputValue = event.target.value.toUpperCase(); // Convert to uppercase
    console.log( inputValue );

    // Allow only A, B, C, D, or blank
    if ( ![ "A", "B", "C", "D", "" ].includes( inputValue ) )
    {
      event.target.value = "";
      inputValue = "";
      ErrorNotificationMsg( "Grade should be  A or B or C or D " );
    }

    let items = [ ...marksList ];
    let documentIndex = items.findIndex(
      ( res ) => res.classId === student.classId
    );
    let item = { ...items[ documentIndex ] };

    items[ documentIndex ] = item;
    item.marks[ indicator ] = inputValue;

    setMarksList( items );
  };

  const handleMarksChange = async ( student ) =>
  {

    const validKeys = [ "ls", "cs", "es", "cos", "csk", "fs", "cspss", "scs", "dms", "is", "os", "ecs", "rs", "aspa", "l" ]; // Keys to check

    const isValid = validKeys.every(
      ( key ) => student.marks[ key ] !== null && [ "A", "B", "C", "D" ].includes( student.marks[ key ] )
    );

    if ( !isValid )
    {
      ErrorNotificationMsg( "Enter grade in all the indicators box" );
      return;
    }

    const payload = {
      form_data: {
        marks: [
          {
            classId: student.classId,
            ls: student.marks.ls,
            cs: student.marks.cs,
            es: student.marks.es,
            cos: student.marks.cos,
            csk: student.marks.csk,
            fs: student.marks.fs,
            cspss: student.marks.cspss,
            scs: student.marks.scs,
            dms: student.marks.dms,
            is: student.marks.is,
            os: student.marks.os,
            ecs: student.marks.ecs,
            rs: student.marks.rs,
            aspa: student.marks.aspa,
            l: student.marks.l,


          },
        ],
      }
    };

    try
    {
      const res = await postRequest(
        "exam-marks-tabulation-dpls-update",
        payload
      );

      if ( res.data.error === 0 )
      {
        SuccessNotificationMsg( "Marks saved successfully." );
      } else
      {
        ErrorNotificationMsg( "Sorry!! Unable to save marks." );
      }
    } catch ( error )
    {
      ErrorNotificationMsg( "Something went wrong!!" );
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div id="panel-1" className="panel">
            <div className="panel-hdr">
              <h2>Student List of Class : { props.classSection }</h2>
            </div>
            <div className="panel-container show">
              <div className="panel-content">
                <Form autoComplete="off" layout="vertical" ref={ formRef }>

                  <div className="table-responsive">
                    <table className="table table-sm table-bordered table-hover">
                      <thead className="thead-themed">
                        <tr className="text-center">
                          <th>ROLL</th>
                          <th>NAME</th>
                          <th>LS</th>
                          <th>CS</th>
                          <th>ES</th>
                          <th>COS</th>
                          <th>CSK</th>
                          <th>FS</th>
                          <th>CSPSS</th>
                          <th>SCS</th>
                          <th>DMS</th>
                          <th>IS</th>
                          <th>OS</th>
                          <th>ECS</th>
                          <th>RS</th>
                          <th>ASPA</th>
                          <th>L</th>

                          <th>ACTION</th>
                        </tr>
                      </thead>
                      <tbody>
                        { marksList &&
                          marksList.map( ( student ) =>
                          {
                            return (
                              <tr key={ student?.classId }>
                                <td>{ student?.stdRoll }</td>
                                <td>
                                  <strong>{ student?.stdName }</strong>
                                </td>


                                { marksKeys.map( ( key ) => (
                                  <td key={ key }>
                                    { tabulation?.lockTabulation === 1 ? (
                                      <Input
                                        type="text"
                                        name="marks"
                                        value={ student.marks ? student.marks[ key ] : "" }
                                        disabled={ true }
                                      />
                                    ) : (
                                      <Input
                                        type="text"
                                        name="marks"
                                        value={ student.marks ? student.marks[ key ] : "" }
                                        id={ `inputId${ student?.classId }_${ key }` }
                                        onBlur={ ( event ) => checkMarks( event, student, key ) }
                                        onChange={ ( event ) => checkMarks( event, student, key ) }
                                      />
                                    ) }
                                  </td>
                                ) ) }


                                {/* Add button */ }
                                <td className="text-center">
                                  <button
                                    className={ `btn btn-sm ${ student.marks.upBy ? "btn-success" : "btn-outline-info"
                                      }` }
                                    onClick={ () => handleMarksChange( student ) }
                                  >
                                    { student.marks.upBy ? "Update" : "Save" }
                                  </button>
                                </td>

                              </tr>
                            );
                          } ) }
                      </tbody>
                    </table>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentListDPLS;
