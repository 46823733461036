import React, { useState, useEffect, useRef } from "react";
import { Form, Input, Button, Space } from "antd";
import { postRequest } from "../../axios";
import
{
  getSessionData,
  getSchoolData,
  getUserData,
} from "../../utils/Helpers";

import
{
  ErrorNotificationMsg,
  SuccessNotificationMsg,
} from "../../utils/NotificationHelper";
import { useOutletContext } from "react-router-dom";

const StudentListBCO = ( props ) =>
{
  const formRef = useRef();
  const [ tabulation, setTabulation ] = useState( null );
  const [ marksList, setMarksList ] = useState( [] );
  const [ btnLoading, setBtnLoading ] = useState( false );
  const setSpinner = useOutletContext();

  useEffect( () =>
  {
    getTabulation();
  }, [] );

  const getTabulation = async () =>
  {
    setSpinner( true );
    const response = await postRequest(
      "exam-marks-tabulation-bco-all-student",
      {
        sessionCode: getSessionData().rcode,
        classSection: props.classSection,
        examId: props.examId,
      }
    );

    setSpinner( false );
    if ( response.data.error > 0 )
    {
      ErrorNotificationMsg( response.data.errmsg );
    } else
    {
      console.log( response.data.response.data );
      setTabulation( response.data.response.data );
      setMarksList( response.data.response.data.students );
    }
  };

  const checkMarks = ( event, student, indicator ) =>
  {
    console.log( "Checking marks" );
    console.log( event );

    let inputValue = event.target.value.toUpperCase(); // Convert to uppercase
    console.log( inputValue );

    // Allow only A, B, C, D, or blank
    if ( ![ "A", "B", "C", "D", "" ].includes( inputValue ) )
    {
      event.target.value = "";
      inputValue = "";
      ErrorNotificationMsg( "Grade should be  A or B or C or D " );
    }

    let items = [ ...marksList ];
    let documentIndex = items.findIndex(
      ( res ) => res.classId === student.classId
    );
    let item = { ...items[ documentIndex ] };

    items[ documentIndex ] = item;
    item.marks[ indicator ] = inputValue;

    setMarksList( items );
  };

  const handleMarksChange = async ( student ) =>
  {

    const validKeys = [ "sa", "cs", "ctc", "els", "ct", "cat", "psadec", "dms", "cps", "aa", "tpo" ]; // Keys to check

    const isValid = validKeys.every(
      ( key ) => student.marks[ key ] !== null && [ "A", "B", "C", "D" ].includes( student.marks[ key ] )
    );

    if ( !isValid )
    {
      ErrorNotificationMsg( "Enter grade in all the indicators box" );
      return;
    }

    const payload = {
      examId: props.examId,
      form_data: {
        marks: [
          {
            classId: student.classId,
            sa: student.marks.sa,
            cs: student.marks.cs,
            ctc: student.marks.ctc,
            els: student.marks.els,
            ct: student.marks.ct,
            cat: student.marks.cat,
            psadec: student.marks.psadec,
            dms: student.marks.dms,
            cps: student.marks.cps,
            aa: student.marks.aa,
            tpo: student.marks.tpo,
          },
        ],
      }
    };

    try
    {
      const res = await postRequest(
        "exam-marks-tabulation-bco-update",
        payload
      );

      if ( res.data.error === 0 )
      {
        // SuccessNotificationMsg("Success", "Marks successfully updated.");
        // props.handleShowList(false);
      } else
      {
        ErrorNotificationMsg( "Sorry!! Unable to save marks." );
      }
    } catch ( error )
    {
      ErrorNotificationMsg( "Something went wrong!!" );
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div id="panel-1" className="panel">
            <div className="panel-hdr">
              <h2>Student List of Class : { props.classSection }</h2>
            </div>
            <div className="panel-container show">
              <div className="panel-content">
                <Form autoComplete="off" layout="vertical" ref={ formRef }>

                  <div className="table-responsive">
                    <table className="table table-sm table-bordered table-hover">
                      <thead className="thead-themed">
                        <tr className="text-center">
                          <th>ROLL</th>
                          <th>NAME</th>
                          <th>SA</th>
                          <th>CS</th>
                          <th>CTC</th>
                          <th>ELS</th>
                          <th>CT</th>
                          <th>CAT</th>
                          <th>PSADEC</th>
                          <th>DMS</th>
                          <th>CPS</th>
                          <th>AA</th>
                          <th>TPO</th>
                          <th>ACTION</th>
                        </tr>
                      </thead>
                      <tbody>
                        { marksList &&
                          marksList.map( ( student ) =>
                          {
                            return (
                              <tr key={ student?.classId }>
                                <td>{ student?.stdRoll }</td>
                                <td>
                                  <strong>{ student?.stdName }</strong>
                                </td>

                                {/* for sa */ }
                                <td>
                                  { tabulation?.lockTabulation === 1 ? (
                                    <Input
                                      type="text"
                                      name="marks"
                                      //value={student?.marks}
                                      value={ student.marks.sa }
                                      disabled={ true }
                                    />
                                  ) : (
                                    <Input
                                      type="text"
                                      name="marks"
                                      //value={student?.marks}
                                      value={ student.marks.sa }
                                      id={ `inputId${ student?.classId }_sa` }
                                      onBlur={ ( event ) =>
                                        checkMarks( event, student, "sa" )
                                      }
                                      onChange={ ( event ) =>
                                        checkMarks( event, student, "sa" )
                                      }
                                    />
                                  ) }
                                </td>

                                {/* for cs */ }
                                <td>
                                  { tabulation?.lockTabulation === 1 ? (
                                    <Input
                                      type="text"
                                      name="marks"
                                      //value={student?.marks}
                                      value={ student.marks.cs }
                                      disabled={ true }
                                    />
                                  ) : (
                                    <Input
                                      type="text"
                                      name="marks"
                                      //value={student?.marks}
                                      value={ student.marks.cs }
                                      id={ `inputId${ student?.classId }_cs` }
                                      onBlur={ ( event ) =>
                                        checkMarks( event, student, "cs" )
                                      }
                                      onChange={ ( event ) =>
                                        checkMarks( event, student, "cs" )
                                      }
                                    />
                                  ) }
                                </td>

                                {/* for ctc */ }
                                <td>
                                  { tabulation?.lockTabulation === 1 ? (
                                    <Input
                                      type="text"
                                      name="marks"
                                      //value={student?.marks}
                                      value={ student.marks.ctc }
                                      disabled={ true }
                                    />
                                  ) : (
                                    <Input
                                      type="text"
                                      name="marks"
                                      //value={student?.marks}
                                      value={ student.marks.ctc }
                                      id={ `inputId${ student?.classId }_ctc` }
                                      onBlur={ ( event ) =>
                                        checkMarks( event, student, "ctc" )
                                      }
                                      onChange={ ( event ) =>
                                        checkMarks( event, student, "ctc" )
                                      }
                                    />
                                  ) }
                                </td>

                                {/* for els */ }
                                <td>
                                  { tabulation?.lockTabulation === 1 ? (
                                    <Input
                                      type="text"
                                      name="marks"
                                      //value={student?.marks}
                                      value={ student.marks.els }
                                      disabled={ true }
                                    />
                                  ) : (
                                    <Input
                                      type="text"
                                      name="marks"
                                      //value={student?.marks}
                                      value={ student.marks.els }
                                      id={ `inputId${ student?.classId }_els` }
                                      onBlur={ ( event ) =>
                                        checkMarks( event, student, "els" )
                                      }
                                      onChange={ ( event ) =>
                                        checkMarks( event, student, "els" )
                                      }
                                    />
                                  ) }
                                </td>

                                {/* for ct */ }
                                <td>
                                  { tabulation?.lockTabulation === 1 ? (
                                    <Input
                                      type="text"
                                      name="marks"
                                      //value={student?.marks}
                                      value={ student.marks.ct }
                                      disabled={ true }
                                    />
                                  ) : (
                                    <Input
                                      type="text"
                                      name="marks"
                                      //value={student?.marks}
                                      value={ student.marks.ct }
                                      id={ `inputId${ student?.classId }_ct` }
                                      onBlur={ ( event ) =>
                                        checkMarks( event, student, "ct" )
                                      }
                                      onChange={ ( event ) =>
                                        checkMarks( event, student, "ct" )
                                      }
                                    />
                                  ) }
                                </td>

                                {/* for cat */ }
                                <td>
                                  { tabulation?.lockTabulation === 1 ? (
                                    <Input
                                      type="text"
                                      name="marks"
                                      //value={student?.marks}
                                      value={ student.marks.cat }
                                      disabled={ true }
                                    />
                                  ) : (
                                    <Input
                                      type="text"
                                      name="marks"
                                      //value={student?.marks}
                                      value={ student.marks.cat }
                                      onBlur={ ( event ) =>
                                        checkMarks( event, student, "cat" )
                                      }
                                      onChange={ ( event ) =>
                                        checkMarks( event, student, "cat" )
                                      }
                                    />
                                  ) }
                                </td>

                                {/* for psadec */ }
                                <td>
                                  { tabulation?.lockTabulation === 1 ? (
                                    <Input
                                      type="text"
                                      name="marks"
                                      //value={student?.marks}
                                      value={ student.marks.psadec }
                                      disabled={ true }
                                    />
                                  ) : (
                                    <Input
                                      type="text"
                                      name="marks"
                                      //value={student?.marks}
                                      value={ student.marks.psadec }
                                      id={ `inputId${ student?.classId }_psadec` }
                                      onBlur={ ( event ) =>
                                        checkMarks(
                                          event,
                                          student,
                                          "psadec"
                                        )
                                      }
                                      onChange={ ( event ) =>
                                        checkMarks(
                                          event,
                                          student,
                                          "psadec"
                                        )
                                      }
                                    />
                                  ) }
                                </td>

                                {/* for DMS */ }
                                <td>
                                  { tabulation?.lockTabulation === 1 ? (
                                    <Input
                                      type="text"
                                      name="marks"
                                      //value={student?.marks}
                                      value={ student.marks.dms }
                                      disabled={ true }
                                    />
                                  ) : (
                                    <Input
                                      type="text"
                                      name="marks"
                                      //value={student?.marks}
                                      value={ student.marks.dms }
                                      id={ `inputId${ student?.classId }_dms` }
                                      onBlur={ ( event ) =>
                                        checkMarks( event, student, "dms" )
                                      }
                                      onChange={ ( event ) =>
                                        checkMarks( event, student, "dms" )
                                      }
                                    />
                                  ) }
                                </td>

                                {/* for CPS */ }
                                <td>
                                  { tabulation?.lockTabulation === 1 ? (
                                    <Input
                                      type="text"
                                      name="marks"
                                      //value={student?.marks}
                                      value={ student.marks.cps }
                                      disabled={ true }
                                    />
                                  ) : (
                                    <Input
                                      type="text"
                                      name="marks"
                                      //value={student?.marks}
                                      value={ student.marks.cps }
                                      id={ `inputId${ student?.classId }_cps` }
                                      onBlur={ ( event ) =>
                                        checkMarks( event, student, "cps" )
                                      }
                                      onChange={ ( event ) =>
                                        checkMarks( event, student, "cps" )
                                      }
                                    />
                                  ) }
                                </td>

                                {/* for AA */ }
                                <td>
                                  { tabulation?.lockTabulation === 1 ? (
                                    <Input
                                      type="text"
                                      name="marks"
                                      //value={student?.marks}
                                      value={ student.marks.aa }
                                      disabled={ true }
                                    />
                                  ) : (
                                    <Input
                                      type="text"
                                      name="marks"
                                      //value={student?.marks}
                                      value={ student.marks.aa }
                                      id={ `inputId${ student?.classId }_aa` }
                                      onBlur={ ( event ) =>
                                        checkMarks( event, student, "aa" )
                                      }
                                      onChange={ ( event ) =>
                                        checkMarks( event, student, "aa" )
                                      }
                                    />
                                  ) }
                                </td>

                                {/* for TPO */ }
                                <td>
                                  { tabulation?.lockTabulation === 1 ? (
                                    <Input
                                      type="text"
                                      name="marks"
                                      //value={student?.marks}
                                      value={ student.marks.tpo }
                                      disabled={ true }
                                    />
                                  ) : (
                                    <Input
                                      type="text"
                                      name="marks"
                                      //value={student?.marks}
                                      value={ student.marks.tpo }
                                      id={ `inputId${ student?.classId }_tpo` }
                                      onBlur={ ( event ) =>
                                        checkMarks( event, student, "tpo" )
                                      }
                                      onChange={ ( event ) =>
                                        checkMarks( event, student, "tpo" )
                                      }
                                    />
                                  ) }
                                </td>

                                {/* Add button */ }
                                <td className="text-center">
                                  <button
                                    className={ `btn btn-sm ${ student.marks.upBy ? "btn-success" : "btn-outline-info"
                                      }` }
                                    onClick={ () => handleMarksChange( student ) }
                                  >
                                    { student.marks.upBy ? "Update" : "Save" }
                                  </button>
                                </td>


                              </tr>
                            );
                          } ) }
                      </tbody>
                    </table>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentListBCO;
