import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Form, Modal, Button } from "antd";
import { postRequest } from "../../axios";
import
  {
    getSessionData,
  } from "../../utils/Helpers";

import
  {
    ErrorNotificationMsg,
  } from "../../utils/NotificationHelper";
import { useOutletContext } from "react-router-dom";

const StudentListLPCD = ( props ) =>
{
  const formRef = useRef();
  const [ tabulation, setTabulation ] = useState( null );
  const [ marksList, setMarksList ] = useState( [] );
  const [ questionList, setQuestionist ] = useState( {} );
  const [ selectStudent, setSelectStudent ] = useState( {} );
  const setSpinner = useOutletContext();

  const [ showModel, setShowModel ] = useState( false );

  useEffect( () =>
  {
    getQuestion();
    getTabulation();
  }, [] );

  const getQuestion = async () =>
  {
    const response = await postRequest(
      "exam-marks-tabulation-lpcd-setting",
      {}
    );

    if ( response.data.error > 0 )
    {
      ErrorNotificationMsg( response.data.errmsg );
    } else
    {
      setQuestionist( response.data.response );
    }
  };

  // Mapping object for category labels
  const categoryLabels = {
    poi: "Pattern of Intelligence",
    aoi: "Area of Interest",
    pa: "Positive Attitude",
    ea: "Exceptional Ability",
    foa: "Features of Anxiety",
    lg: "Learning Gaps",
    sld: "Specific Learning Difficulties",
  };

  const getTabulation = async () =>
  {
    setSpinner( true );
    const response = await postRequest(
      "exam-marks-tabulation-lpcd-all-student",
      {
        sessionCode: getSessionData().rcode,
        classSection: props.classSection,
        examId: props.examId,
      }
    );

    setSpinner( false );
    if ( response.data.error > 0 )
    {
      ErrorNotificationMsg( response.data.errmsg );
    } else
    {
      setTabulation( response.data.response.data );
      setMarksList( response.data.response.data.students );
    }
  };

  const hideModelFunction = () =>
  {
    setSelectStudent( {} );
    setShowModel( false );
  };

  const showModelFunction = ( student ) =>
  {
    setSelectStudent( student );
    setShowModel( true );

    // getNoticeBoard();
  };

  const handleSelectChange = ( category, event ) =>
  {
    selectStudent.marks[ category ] = event.target.value;
    setSelectStudent( selectStudent );
  };

  const handleMarksChange = async () =>
  {
    let emptyCount = 0;

    if ( selectStudent.marks.poi == null || selectStudent.marks.poi === "" )
      emptyCount++;
    if ( selectStudent.marks.aoi == null || selectStudent.marks.aoi === "" )
      emptyCount++;
    if ( selectStudent.marks.pa == null || selectStudent.marks.pa === "" )
      emptyCount++;
    if ( selectStudent.marks.ea == null || selectStudent.marks.ea === "" )
      emptyCount++;
    if ( selectStudent.marks.foa == null || selectStudent.marks.foa === "" )
      emptyCount++;
    if ( selectStudent.marks.lg == null || selectStudent.marks.lg === "" )
      emptyCount++;
    if ( selectStudent.marks.sld == null || selectStudent.marks.sld === "" )
      emptyCount++;

    console.log( "emptyCount", emptyCount );
    console.log( "selectStudent", selectStudent );

    if ( emptyCount !== 0 )
    {
      ErrorNotificationMsg( " Please fill in the all missing data." );
      return;
    }

    const payload = {
      examId: props.examId, //
      form_data: {
        marks: [
          {
            classId: selectStudent.classId,
            poi: selectStudent.marks.poi,
            aoi: selectStudent.marks.aoi,
            pa: selectStudent.marks.pa,
            ea: selectStudent.marks.ea,
            foa: selectStudent.marks.foa,
            lg: selectStudent.marks.lg,
            sld: selectStudent.marks.sld,
          },
        ],
      },
    };

    console.log( "payload", payload );

    try
    {
      const res = await postRequest(
        "exam-marks-tabulation-lpcd-update",
        payload
      );

      if ( res.data.error === 0 )
      {
        // SuccessNotificationMsg("Success", "Marks successfully updated.");
        // props.handleShowList(false);
      } else
      {
        ErrorNotificationMsg( "Sorry!! Unable to save marks." );
      }
    } catch ( error )
    {
      ErrorNotificationMsg( "Something went wrong!!" );
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div id="panel-1" className="panel">
            <div className="panel-hdr">
              <h2>Student List of Class : { props.classSection }</h2>
            </div>
            <div className="panel-container show">
              <div className="panel-content">
                <Form autoComplete="off" layout="vertical" ref={ formRef }>
                  <div className="table-responsive">
                    <table className="table table-sm table-bordered table-hover">
                      <thead className="thead-themed">
                        <tr className="text-center">
                          <th>ROLL</th>
                          <th>NAME</th>
                          <th>Action</th>
                          {/* { tabulation?.fullMarks &&
                            Object.entries( tabulation.fullMarks ).map( ( [ key, value ] ) => (
                              <th key={ key }>
                                { key.toUpperCase() } [F.M.- { value }]
                              </th>
                            ) ) } */}
                        </tr>
                      </thead>
                      <tbody>
                        { marksList &&
                          marksList.map( ( student ) =>
                          {
                            return (
                              <tr key={ student?.classId }>
                                <td>{ student?.stdRoll }</td>
                                <td>
                                  <strong>{ student?.stdName }</strong>
                                </td>

                                {/* Add button */ }
                                <td className="text-center">
                                  <button
                                    className={ `btn btn-sm ${ student.marks.upBy
                                        ? "btn-success"
                                        : "btn-outline-info"
                                      }` }
                                    onClick={ () => showModelFunction( student ) }>
                                    { student.marks.upBy ? "Edit" : "Add" }
                                  </button>
                                </td>
                              </tr>
                            );
                          } ) }
                      </tbody>
                    </table>
                  </div>
                </Form>

                <Modal
                  title="LPCD Data"
                  open={ showModel }
                  onOk={ hideModelFunction }
                  okText="Close"
                  okType="secondary"
                  onCancel={ hideModelFunction }
                  cancelButtonProps={ { style: { display: "none" } } }>
                  <div className="row">
                    <div className="col-md-12">
                      <span className="d-block">
                        Roll No : <strong> { selectStudent?.stdRoll }</strong>
                      </span>

                      <span className="d-block">
                        Student Name :{ " " }
                        <strong> { selectStudent?.stdName }</strong>
                      </span>

                      {/* { Object.entries( questionList )
                        .map(
                          ( [ category, items ] ) => (
                            <Col key={ category } xs={ 24 } sm={ 12 }>
                              <div class="form-group grid grid-cols-2 items-center gap-4">
                                <span className="d-block">
                                  { categoryLabels[ category ] || category } :{ " " }
                                  <strong> { selectStudent.marks ? selectStudent.marks[ category ] : "" }</strong>
                                </span>

                              </div>
                            </Col>
                          )
                        ) } */}

                      <Row gutter={ [ 16, 16 ] }>
                        { Object.entries( questionList ).map(
                          ( [ category, items ] ) => (
                            <Col key={ category } xs={ 24 } sm={ 12 }>
                              <div class="form-group grid grid-cols-2 items-center gap-4">
                                <h3 class="text-lg font-semibold">
                                  { categoryLabels[ category ] || category }
                                </h3>

                                <select
                                  class="border rounded-md p-2"
                                  // defaultValue={ `${ selectStudent.marks ? selectStudent.marks[ category ] : ""}` }
                                  onChange={ ( value ) =>
                                    handleSelectChange( category, value )
                                  }>
                                  <option value="">
                                    { " " }
                                    { `Select ${ category }` }
                                  </option>
                                  { items.map( ( item, idx ) => (
                                    <option
                                      key={ `${ category }-${ idx }` }
                                      value={ item }>
                                      { item }
                                    </option>
                                  ) ) }
                                </select>
                              </div>
                            </Col>
                          )
                        ) }
                      </Row>
                    </div>
                  </div>

                  <hr />

                  {/* Save Data Button */ }
                  <div className="text-center mt-4">
                    <Button
                      onClick={ handleMarksChange }
                      type="primary"
                      disabled={ tabulation?.lockTabulation === 1 }>
                      Save Data
                    </Button>
                  </div>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentListLPCD;
